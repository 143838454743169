import { capitalize } from "lodash";
import { ReactComponent as DisplayLogo } from "@/assets/dashboard/common/display.svg";
import { ReactComponent as LinkedinLogo } from "@/assets/dashboard/common/linkedin.svg";
import { ReactComponent as MetaLogo } from "@/assets/dashboard/common/meta-min.svg";
import { ReactComponent as DV360Logo } from "@/assets/dashboard/common/dv360.svg";
import { ReactComponent as TradeDeskLogo } from "@/assets/dashboard/common/tradedesk.svg";
import { ReactComponent as HeyreachLogo } from "@/assets/dashboard/common/heyreach.svg";
import { ReactComponent as SmartleadLogo } from "@/assets/dashboard/common/smartlead.svg";

import config from "./whiteLabel.config";
import routes from "./routes";
import { FavSettingsArrayItem } from "./types";
const appKey = import.meta.env.VITE_APP;
export const BRAND = config[appKey as keyof typeof config].brand;
export const DRAWER_WIDTH = 312;
export const CLOSED_DRAWER_WIDTH = 65;
export const NAV_HEIGHT = 98;
export const LAYOUT_MARGIN_TOP = 50; //should be in pixel
export const DRAWER_HEAD_HEIGHT = 98;
export const FORM_COLUMN_SPACING = 2;
export const FORM_ROW_SPACING = 1.5;
export const LAYOUT_PRIMARY_COLUMN_SPACING = 3;
export const LAYOUT_PRIMARY_ROW_SPACING = 3;
export const LAYOUT_SECONDARY_COLUMN_SPACING = 2;
export const LAYOUT_SECONDARY_ROW_SPACING = 2;
export const FONT_FAMILY = "Poppins, sans-serif";
export const BORDER_RADIUS = "8px";
export const BORDER_RADIUS_SECONDARY = "4px";
export const TOKEN_KEY = "token";
export const USER_KEY = "user";
export const PREVIOUS_USER_KEY = "previousUser";
export const BOX_SHADOW = "0px 0px 10px 2px rgba(176, 202, 238, 0.25)";
export const MARGINTOP_FOR_FIELD_LABEL = "36px";
export const ADJUSTED_MARGIN_FOR_FIELD = `calc(${MARGINTOP_FOR_FIELD_LABEL} - 4px)`;
export const FavSettingsArray: FavSettingsArrayItem[] = [
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    name: "apple-touch-icon",
    href: "/apple-touch-icon.png",
    fileExtension: "png",
  },
  {
    rel: "icon",
    sizes: "32x32",
    href: "/favicon-32x32.png",
    type: "image/png",
    name: "favicon-32x32",
    fileExtension: "png",
  },
  {
    rel: "icon",
    sizes: "16x16",
    href: "/favicon-16x16.png",
    type: "image/png",
    name: "favicon-16x16",
    fileExtension: "png",
  },
  {
    rel: "manifest",
    href: "/site.webmanifest",
    name: "site",
    fileExtension: "webmanifest",
  },
];

export const CHANNELS = {
  DISPLAY: "display",
  LINKEDIN: "linkedin",
  FACEBOOK: "facebook",
  HEYREACH: "heyreach",
  SMARTLEAD: "smartlead",
} as const;

export type CHANNELS_VALUES = (typeof CHANNELS)[keyof typeof CHANNELS];

export const DESTINATION = {
  DV360: "dv360",
  TRADEDESK: "tradeDesk",
} as const;
export const EXTENDED_CHANNELS = {
  ...CHANNELS,
  ...DESTINATION,
} as const;
export const CRM = {
  SALESFORCE: "salesforce",
  HUBSPOT: "hubSpot",
} as const;

export const EXTENDED_CHANNELS_CRM = {
  ...CHANNELS,
  ...DESTINATION,
  ...CRM,
} as const;
export const CHANNELS_ICONS = {
  LINKEDIN: LinkedinLogo,
  DISPLAY: DisplayLogo,
  HEYREACH: HeyreachLogo,
  DV360: DV360Logo,
  TRADEDESK: TradeDeskLogo,
  FACEBOOK: MetaLogo,
  SMARTLEAD: SmartleadLogo,
} as const;
export const CHANNELS_LABEL_VALUE = Object.values(CHANNELS).map((item) => ({
  label: capitalize(item),
  value: item,
}));
export const channelListForFilter = [
  { label: "All", value: "all" },
  ...CHANNELS_LABEL_VALUE,
];

export const CHANNELS_TO_CAMPAIGN: Record<CHANNELS_VALUES, string> = {
  [CHANNELS.DISPLAY]: "BeeswaxCampaign",
  [CHANNELS.LINKEDIN]: "LinkedinCampaign",
  [CHANNELS.FACEBOOK]: "FacebookCampaign",
  [CHANNELS.HEYREACH]: "HeyreachCampaign",
  [CHANNELS.SMARTLEAD]: "SmartleadCampaign",
};

export const SMARTLEAD_ACCOUNT_TYPE = {
  PLATFORM: "PLATFORM",
  AGENCY: "AGENCY",
};

// Date Formats
export const DATE_FORMAT_ONE = "YYYY/MM/DD";
export const DATE_FORMAT_TWO = "YYYY-MM-DD";
export const DATE_FORMAT_THREE = "D MMM YYYY hh:mm a";
export const DATE_FORMAT_FOUR = "YYYYMMDD";
export const DATE_FORMAT_FIVE = "YY/MM/DD";
export const DATE_FORMAT_SIX = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT_SEVEN = "MMMM YYYY";
export const DATE_FORMAT_EIGHT = "YYYYMM";

export const USER_TYPE = {
  SUPER_ADMIN: "superAdmin",
  AGENCY_ADMIN: "agencyAdmin",
  AGENCY_USER: "agencyUser",
  ADVERTISER: "advertiserUser",
} as const;
export const USER_TYPE_LABELS = {
  [USER_TYPE.SUPER_ADMIN]: "Super Admin",
  [USER_TYPE.AGENCY_ADMIN]: "Agency Admin",
  [USER_TYPE.AGENCY_USER]: "Agency User",
  [USER_TYPE.ADVERTISER]: "Advertiser User",
};
export const ALLOWED_VIDEO_TYPES = ["mp4"];
export const ALLOWED_IMAGES_TYPES = ["png", "jpeg", "jpg", "gif"];

export const SCOPES = ["R", "RU", "CRUD"] as const;
export const MODULES = {
  DASHBOARD: "DASHBOARD",
  INTEGRATIONS_BEESWAX: "INTEGRATIONS_BEESWAX",
  INTEGRATIONS_FACEBOOK: "INTEGRATIONS_FACEBOOK",
  INTEGRATIONS_LINKEDIN: "INTEGRATIONS_LINKEDIN",
  INTEGRATIONS_HEYREACH: "INTEGRATIONS_HEYREACH",
  INTEGRATIONS_SMARTLEAD: "INTEGRATIONS_SMARTLEAD",
  INTEGRATIONS_SALESFORCE: "INTEGRATIONS_SALESFORCE",
  INTEGRATIONS_HUBSPOT: "INTEGRATIONS_HUBSPOT",
  INTEGRATIONS_DISPLAYVIDEO360: "INTEGRATIONS_DISPLAYVIDEO360",
  INTEGRATIONS_TRADEDESK: "INTEGRATIONS_TRADEDESK",
  ADVERTISERS: "ADVERTISERS",
  ADVERTISERS_INTEGRATIONS_LINKEDIN: "ADVERTISERS_INTEGRATIONS_LINKEDIN",
  ADVERTISERS_INTEGRATIONS_SALESFORCE: "ADVERTISERS_INTEGRATIONS_SALESFORCE",
  ADVERTISERS_INTEGRATIONS_HUBSPOT: "ADVERTISERS_INTEGRATIONS_HUBSPOT",
  ADVERTISERS_INTEGRATIONS_DISPLAYVIDEO360:
    "ADVERTISERS_INTEGRATIONS_DISPLAYVIDEO360",
  ADVERTISERS_INTEGRATIONS_TRADEDESK: "ADVERTISERS_INTEGRATIONS_TRADEDESK",
  CAMPAIGNS: "CAMPAIGNS",
  QUICK_CAMPAIGNS: "QUICK_CAMPAIGNS",
  AUDIENCE_ACCOUNT: "AUDIENCE_ACCOUNT",
  AUDIENCE_SITE_VISITOR: "AUDIENCE_SITE_VISITOR",
  AUDIENCE_EMAIL: "AUDIENCE_EMAIL",
  AUDIENCE_MANAGER: "AUDIENCE_MANAGER",
  ADVERTISER_USERS: "ADVERTISER_USERS",
  AGENCY_USERS: "AGENCY_USERS",
  ATTRIBUTION: "ATTRIBUTION",
  CREATIVES: "CREATIVES",
  CREATIVE_TEMPLATES: "CREATIVE_TEMPLATES",
  FORMS: "FORMS",
  LEAD_APPEND: "LEAD_APPEND",
  UTMS: "UTMS",
  TAGS: "TAGS",
  CONVERSIONS: "CONVERSIONS",
  COMPANY_VISITOR_INSIGHTS: "COMPANY_VISITOR_INSIGHTS",
  PROFILE_VISITOR_INSIGHTS: "PROFILE_VISITOR_INSIGHTS",
  CONTACT_BI: "CONTACT_BI",
  CAMPAIGN_STATS: "CAMPAIGN_STATS",
  CAMPAIGN_STATS_COMPLETE: "CAMPAIGN_STATS_COMPLETE",
  ABM_STATS: "ABM_STATS",
  REPORTS_SETUP: "REPORTS_SETUP",
  TARGETING_EXPRESSION_PRESET: "TARGETING_EXPRESSION_PRESET",
} as const;

export const ADVERTISER_SPECIFIC_MODULES = [
  MODULES.INTEGRATIONS_HUBSPOT,
  MODULES.INTEGRATIONS_SALESFORCE,
  MODULES.INTEGRATIONS_DISPLAYVIDEO360,
  MODULES.INTEGRATIONS_TRADEDESK,
] as const;

// add other Integration module for agency as there is no parent integration module
export const AGENCY_INTEGRATION_MODULES = [
  MODULES.INTEGRATIONS_LINKEDIN,
  MODULES.INTEGRATIONS_BEESWAX,
  MODULES.INTEGRATIONS_HEYREACH,
  MODULES.INTEGRATIONS_SMARTLEAD,
  MODULES.INTEGRATIONS_FACEBOOK,
] as const;

export const EXTENDED_CHANNELS_AGENCY_PERMISSIONS = {
  [EXTENDED_CHANNELS.DISPLAY]: MODULES.INTEGRATIONS_BEESWAX,
  [EXTENDED_CHANNELS.LINKEDIN]: MODULES.INTEGRATIONS_LINKEDIN,
  [EXTENDED_CHANNELS.FACEBOOK]: MODULES.INTEGRATIONS_FACEBOOK,
  [EXTENDED_CHANNELS.HEYREACH]: MODULES.INTEGRATIONS_HEYREACH,
  [EXTENDED_CHANNELS.SMARTLEAD]: MODULES.INTEGRATIONS_SMARTLEAD,
} as const;

export const EXTENDED_CHANNELS_CRM_ADVERTISER_PERMISSIONS = {
  [EXTENDED_CHANNELS.LINKEDIN]: MODULES.INTEGRATIONS_LINKEDIN,
  [EXTENDED_CHANNELS.FACEBOOK]: MODULES.INTEGRATIONS_FACEBOOK,
  [EXTENDED_CHANNELS.DV360]: MODULES.INTEGRATIONS_DISPLAYVIDEO360,
  [EXTENDED_CHANNELS.TRADEDESK]: MODULES.INTEGRATIONS_TRADEDESK,
  [CRM.HUBSPOT]: MODULES.INTEGRATIONS_HUBSPOT,
  [CRM.SALESFORCE]: MODULES.INTEGRATIONS_SALESFORCE,
};

export const BREADCRUMBS_MAP = {
  [routes.REPORTS.STATS.INDEX]: "Campaign Stats",
  [routes.REPORTS.ABM.INDEX]: "ABM",
  [routes.REPORTS.LEAD_APPEND.INDEX]: "Intent Stream",
  [routes.VISITOR_INSIGHTS.INDEX]: "Visitor Insights",
  [routes.UTM.INDEX]: "UTMs",
  [routes.AUDIENCES.ACCOUNT_AUDIENCE.INDEX]: "Account Audiences",
  [routes.AUDIENCES.EMAIL_AUDIENCES.INDEX]: "Email Audiences",
  [routes.AUDIENCES.RETARGETING.INDEX]: "Site Visitor Audiences",
  [routes.AUDIENCE_MANAGER.INDEX]: "Audience Manager",
  [routes.CAMPAIGNS.GENERAL_CAMPAIGN.INDEX]: "General",
  [routes.CAMPAIGNS.QUICK_CAMPAIGN.INDEX]: "Quick",
} as const;

export const DEMO_COMPANY_ID = 238;
export const CAMPAIGN_EXECUTION_TYPE = {
  REGULAR: "REGULAR",
  ONETOONE: "1TO1",
} as const;

export const QUOTA_TYPES = {
  CAMPAIGN_IMPRESSIONS: "CAMPAIGN_IMPRESSIONS",
  LINKEDIN_PROFILES_RESOLVED: "LINKEDIN_PROFILES_RESOLVED",
  ADVERTISERS: "ADVERTISERS",
} as const;

export const S3_BUCKET_NAME = {
  CONT_AD_SERVER: "cont-ad-server",
  TARGET_ACCOUNT_FILES: "target-account-files",
} as const;
