import * as React from "react";
import {
  getRetargetMergedAudienes,
  getSiteVisitorAudience,
} from "@/apis/v1/audience";
import {
  getEngagementTimeSpans,
  getEngagementType,
} from "@/apis/v1/retargeting";
import { useQuery } from "@tanstack/react-query";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationSchemaFn from "./siteVisitorAudience.validation";
import { RETARGET_BY } from "./Facebook/data";
import { RETARGET_BY as LINKEDIN_RETARGET_BY } from "./Linkedin/data";
import mapFilterQueryKeys from "@/utils/mapFilterQueryKeys";
import { minutesToSeconds } from "date-fns";
import { useAdvertisersList } from "@/modules/Advertiser/advertiser.hook";
import { RETARGET_BY as DISPLAY_RETARGET_BY } from "./Display/data";
import { HEY_REACH_TYPE } from "@/modules/HeyReach/data";
import { CRITERIA } from "@/modules/VisitorInsight/Criteria/criteria.interface";
import { MATCH_TYPE } from "@/modules/Conversion/data";

export const GET_MERGED_RETARGET_AUDIENCE_KEY = (keys?: unknown[]) => [
  "audiences",
  "site-visiter",
  "get",
  ...mapFilterQueryKeys(keys),
];
export function useSiteVistorAudienceList({
  enabled = true,
  advertiserId,
}: {
  enabled?: boolean;
  advertiserId?: number | null;
} = {}) {
  return useQuery(
    ["audiences", "site-visiter", "display", "get", advertiserId],
    getSiteVisitorAudience,
    {
      enabled,
      meta: {
        queryParams: {
          advertiserId,
        },
      },
    }
  );
}
export function useRetargetAudienceList({
  enabled = true,
  advertiserId,
  page,
  rowsPerPage,
  options,
}: {
  enabled?: boolean;
  advertiserId?: number | null;
  page?: number;
  rowsPerPage?: number;
  options?: any;
} = {}) {
  return useQuery(
    GET_MERGED_RETARGET_AUDIENCE_KEY([advertiserId, page, rowsPerPage]),
    getRetargetMergedAudienes,
    {
      enabled,
      meta: {
        queryParams: {
          advertiserId,
          page,
          rowsPerPage,
        },
      },
      ...options,
    }
  );
}
export function useGetEngagementType({
  enabled = true,
  engagementType,
  onSuccess,
}: {
  enabled?: boolean;
  engagementType: string | null;
  onSuccess?: any;
}) {
  return useQuery(["audiences", "engagementTypes"], getEngagementType, {
    enabled,
    onSuccess: onSuccess,
    meta: {
      queryParams: {
        engagementType,
      },
    },
  });
}
export function useGetEngagementTimeSpan({
  enabled = true,
  engagementType,
  onSuccess,
}: {
  enabled?: boolean;
  engagementType: string | null;
  onSuccess?: any;
}) {
  return useQuery(
    ["audiences", "engagementTimeSpans"],
    getEngagementTimeSpans,
    {
      enabled,
      onSuccess: onSuccess,
      meta: {
        queryParams: {
          engagementType,
        },
      },
    }
  );
}
export function createOrEditHookFormState({
  channel = "display",
  id,
  siteVisitorData,
  loggedInAdvertiserId,
}: {
  channel: "display" | "linkedin" | "facebook" | "heyReach" | "smartlead";
  id: string | undefined;
  siteVisitorData: any;
  loggedInAdvertiserId: any;
}) {
  const [selectedAdvertiser, setSelectedAdvertiser] = React.useState<any>();
  const { data: advertiserList, isLoading: advertisersLoading } =
    useAdvertisersList();
  const [displaySwitch, setDisplaySwitch] = React.useState<boolean>(
    channel === "display" && id ? true : false
  );
  const [heyReachSwitch, setHeyReachSwitch] = React.useState<boolean>(
    channel === "heyReach" && id ? true : false
  );
  const [smartleadSwitch, setSmartleadSwitch] = React.useState<boolean>(
    channel === "smartlead" && id ? true : false
  );
  const [validationSchema, setValidationSchema] = React.useState<any>(
    validationSchemaFn()
  );
  const [linkedinSwitch, setLinkedinSwitch] = React.useState<boolean>(
    channel === "linkedin" && id ? true : false
  );
  const [facebookSwitch, setFacebookSwitch] = React.useState<boolean>(
    channel === "facebook" && id ? true : false
  );
  const { setHookFormValue } = useSetDefaultValues();
  const useFormState = useForm<any>({
    defaultValues: async () => {
      return setHookFormValue({
        channel,
        data: siteVisitorData,
        loggedInAdvertiserId,
        id,
      });
    },
    resolver: yupResolver(validationSchema),
  });
  const watchValue = useWatch({
    control: useFormState.control,
    name: "advertiserId",
  });
  React.useEffect(() => {
    const advertiser = advertiserList?.find(
      (advertiser: any) => advertiser.id == watchValue
    );
    setSelectedAdvertiser(advertiser);
    // Reset form state
    setDisplaySwitch(false);
    setHeyReachSwitch(false);
    setSmartleadSwitch(false);
    setLinkedinSwitch(false);
    setFacebookSwitch(false);
    useFormState.reset(
      setHookFormValue({
        channel,
        data: {
          segmentName: useFormState.getValues("segmentName"),
          companyId: useFormState.getValues("advertiserId"),
        },
        loggedInAdvertiserId,
        id,
      })
    );
  }, [watchValue, advertiserList]);
  return {
    displaySwitch,
    setDisplaySwitch,
    heyReachSwitch,
    setHeyReachSwitch,
    smartleadSwitch,
    setSmartleadSwitch,
    linkedinSwitch,
    setLinkedinSwitch,
    useFormState,
    setValidationSchema,
    validationSchema,
    facebookSwitch,
    setFacebookSwitch,
    advertisersLoading,
    advertiserList,
    selectedAdvertiser,
  };
}
export function useSetDefaultValues() {
  function setHookFormValue({
    channel,
    data,
    loggedInAdvertiserId,
    id,
  }: {
    channel: "display" | "linkedin" | "facebook" | "heyReach" | "smartlead";
    data: any;
    id: any;
    loggedInAdvertiserId: any;
  }) {
    let payload = {
      segmentName: data?.segmentName ?? "",
      advertiserId: loggedInAdvertiserId ?? data?.companyId ?? null,
      linkedin: {
        retargetBy:
          data?.retargetBy ?? LINKEDIN_RETARGET_BY.WEBSITE_RETARGETING,
      },
      heyReach: {
        type: HEY_REACH_TYPE.LEAD,
        visitorInsightType: CRITERIA.PROFILE,
      },
      smartlead: {
        visitorInsightType: CRITERIA.PROFILE,
      },
      display: {
        retargetBy:
          data?.retargetBy ?? DISPLAY_RETARGET_BY.WEBSITE_RETARGETING_KNOWN,
      },
      facebook: {
        retargetBy: data?.retargetBy ?? RETARGET_BY.WEBSITE_RETARGETING,
      },
    };
    if (id) {
      if (channel === "linkedin") {
        const defaultValues: any = {
          engagementType: data?.engagementType ?? null,
          lineItems: data?.lineItemIds ? JSON.parse(data?.lineItemIds) : null,
        };
        if (data?.timeSpan) {
          defaultValues["timeSpan"] = parseInt(data?.timeSpan);
        }
        payload = {
          ...payload,
          linkedin: {
            ...payload.linkedin,
            ...defaultValues,
          },
        };
      }
    }
    return payload;
  }
  return { setHookFormValue };
}
export function useHandleSubmit({
  data,
  linkedinSwitch,
  displaySwitch,
  facebookSwitch,
  heyReachSwitch,
  smartleadSwitch,
}: {
  data: any;
  linkedinSwitch: boolean;
  displaySwitch: boolean;
  facebookSwitch: boolean;
  heyReachSwitch: boolean;
  smartleadSwitch: boolean;
}) {
  const { createWebsiteRetargetRules, createFacebookOrInstagramRetargetRules } =
    useCreateRules();
  let display: any = null;
  let displayVisitorInsightProfile: any = null;
  let displayVisitorInsightCompany: any = null;
  let linkedin: any = null;
  let facebook: any = null;
  let heyReach: any = null;
  let smartLead: any = null;
  if (
    displaySwitch &&
    data?.display &&
    Object.keys(data?.display ?? {}).length >= 0
  ) {
    if (
      data.display.retargetBy ===
      DISPLAY_RETARGET_BY.WEBSITE_RETARGETING_UN_KNOWN
    ) {
      display = {
        advertiserId: data.advertiserId,
        segmentName: `${data.segmentName}`,
        // /The rules are formatted as a 2D array intentionally because Google Tag Manager conversion model requires a 2D array.
        rules: [
          data.display.rules.map((item: any) => ({
            condition: item.filter,
            value: item.url,
            matchType: MATCH_TYPE.PAGE_URL,
          })),
        ],
      };
    }
    if (
      data.display.retargetBy === DISPLAY_RETARGET_BY.WEBSITE_RETARGETING_KNOWN
    ) {
      if (data.display.visitorInsightType === CRITERIA.PROFILE) {
        displayVisitorInsightProfile = {
          advertiserId: data.advertiserId,
          name: data.segmentName,
          criteria: data.display.criteria,
          isRecurring: data.display.isRecurring,
          executionType: data.display.executionType,
          userLookBackWindowInDays: data.display.userLookBackWindowInDays,
        };
      }
      if (data.display.visitorInsightType === CRITERIA.COMPANY) {
        displayVisitorInsightCompany = {
          advertiserId: data.advertiserId,
          name: data.segmentName,
          criteria: data.display.criteria,
          isRecurring: data.display.isRecurring,
          executionType: data.display.executionType,
          userLookBackWindowInDays: data.display.userLookBackWindowInDays,
        };
      }
    }
  }

  if (
    linkedinSwitch &&
    data?.linkedin &&
    Object.keys(data?.linkedin ?? {}).length >= 0
  ) {
    let linkedinPayload = {};
    if (data.linkedin.retargetBy === RETARGET_BY.WEBSITE_RETARGETING) {
      const dateRange: Array<Date | null> = [data.linkedin.startDate];
      if (data.linkedin.dateType === "double")
        dateRange.push(data.linkedin.endDate);
      else dateRange.push(null);
      linkedinPayload = {
        ...linkedinPayload,
        retargetBy: data.linkedin.retargetBy,
        dateRange,
      };
    } else {
      linkedinPayload = {
        ...linkedinPayload,
        ...data.linkedin,
      };
    }
    linkedin = {
      advertiserId: data.advertiserId,
      segmentName: `${data.segmentName} ds`,
      ...linkedinPayload,
    };
  }
  if (
    facebookSwitch &&
    data?.facebook &&
    Object.keys(data?.facebook ?? {}).length >= 0
  ) {
    let rules;
    if (
      [RETARGET_BY.FACEBOOK, RETARGET_BY.INSTAGRAM].includes(
        data.facebook.retargetBy
      )
    ) {
      rules = createFacebookOrInstagramRetargetRules(
        data.facebook.engagementType,
        data.facebook.timeSpan,
        data.facebook.page
      );
    }
    if (data.facebook.retargetBy === RETARGET_BY.WEBSITE_RETARGETING) {
      rules = createWebsiteRetargetRules(
        data.facebook.listUrls,
        data.facebook.timeSpan
      );
    }
    facebook = {
      advertiserId: data.advertiserId,
      segmentName: `${data.segmentName} ds`,
      rules,
      retargetBy: data.facebook.retargetBy,
    };
  }
  if (
    heyReachSwitch &&
    data?.heyReach &&
    Object.keys(data?.heyReach ?? {}).length >= 0
  ) {
    heyReach = {
      advertiserId: data.advertiserId,
      name: data.segmentName,
      leadOrCampaignId: data.heyReach.leadOrCampaignId,
      heyReachType: data.heyReach.type,
      criteria: data.heyReach.criteria,
      isRecurring: data.heyReach.isRecurring,
    };
  }
  if (
    smartleadSwitch &&
    data?.smartlead &&
    Object.keys(data?.smartlead ?? {}).length >= 0
  ) {
    smartLead = {
      advertiserId: data.advertiserId,
      name: data.segmentName,
      campaignId: data.smartlead.campaignId,
      criteria: data.smartlead.criteria,
      isRecurring: data.smartlead.isRecurring,
    };
  }
  return {
    display,
    linkedin,
    facebook,
    heyReach,
    smartLead,
    displayVisitorInsightProfile,
    displayVisitorInsightCompany,
  };
}
export function useCreateRules() {
  function createWebsiteRetargetRules(internalRules: any[], timeSpan: number) {
    const rules: any[] = [];
    internalRules.forEach((item) => {
      rules.push({
        event_sources: [
          {
            type: "pixel",
            id: "433036625780398", //FIXME: remove hardcoded values when pixel functionality is done
          },
        ],
        retention_seconds: timeSpan ? minutesToSeconds(timeSpan) : 0,
        filter: {
          operator: "or",
          filters: item.values.map((val: any) => ({
            field: "url",
            operator: item.type,
            value: val,
          })),
        },
        template: "VISITORS_BY_URL",
      });
    });
    return {
      inclusions: {
        operator: "or",
        rules,
      },
    };
  }
  function createFacebookOrInstagramRetargetRules(
    event: string,
    timeSpan: number,
    page: number
  ) {
    return {
      inclusions: {
        operator: "or",
        rules: [
          {
            event_sources: [
              {
                type: "page",
                id: page,
              },
            ],
            retention_seconds: minutesToSeconds(timeSpan),
            filter: {
              operator: "or",
              filters: [
                {
                  field: "event",
                  operator: "eq",
                  value: event,
                },
              ],
            },
          },
        ],
      },
    };
  }
  return {
    createWebsiteRetargetRules,
    createFacebookOrInstagramRetargetRules,
  };
}
function generateUrls({ listUrls }: { listUrls: any[] }) {
  const rules: any[] = [];
  listUrls.forEach((urlData: any) => {
    const dataToPush = urlData.values.map((url: string) => ({
      filter: urlData.type,
      url,
    }));
    rules.push(...dataToPush);
  });
  return rules;
}
