//audience type
export const AUDIENCE_TYPES = {
  Audience: "BeeswaxAudiences",
  EmailAudiences: "BeeswaxEmailAudiences",
  RetargetAudience: "BeeswaxRetargetAudiences",
};

//audience type
export const CAMPAIGN_TYPE ={
  GENERAL: "GENERAL",
  QUICK: "QUICK",
}


//facebook
export const FACEBOOK_OBJECTIVES = {
  OUTCOME_AWARENESS: "OUTCOME_AWARENESS",
  OUTCOME_TRAFFIC: "OUTCOME_TRAFFIC",
  OUTCOME_ENGAGEMENT: "OUTCOME_ENGAGEMENT",
  OUTCOME_APP_PROMOTION: "OUTCOME_APP_PROMOTION",
  OUTCOME_LEADS: "OUTCOME_LEADS",
  OUTCOME_SALES: "OUTCOME_SALES",
} as const;

export const FACEBOOK_OBJECTIVES_LABELS = {
  AWARENESS: "AWARENESS",
  TRAFFIC: "TRAFFIC",
  ENGAGEMENT: "ENGAGEMENT",
  APP_PROMOTION: "APP PROMOTION",
  LEADS: "LEADS",
  SALES: "SALES",
} as const;

export const FACEBOOK_OPTIMIZATION_GOAL = {
  AD_RECALL_LIFT: "AD_RECALL_LIFT",
  APP_INSTALLS: "APP_INSTALLS",
  APP_INSTALLS_AND_OFFSITE_CONVERSIONS: "APP_INSTALLS_AND_OFFSITE_CONVERSIONS",
  CONVERSATIONS: "CONVERSATIONS",
  DERIVED_EVENTS: "DERIVED_EVENTS",
  ENGAGED_USERS: "ENGAGED_USERS",
  EVENT_RESPONSES: "EVENT_RESPONSES",
  IMPRESSIONS: "IMPRESSIONS",
  IN_APP_VALUE: "IN_APP_VALUE",
  LANDING_PAGE_VIEWS: "LANDING_PAGE_VIEWS",
  LEAD_GENERATION: "LEAD_GENERATION",
  LINK_CLICKS: "LINK_CLICKS",
  MESSAGING_APPOINTMENT_CONVERSION: "MESSAGING_APPOINTMENT_CONVERSION",
  MESSAGING_PURCHASE_CONVERSION: "MESSAGING_PURCHASE_CONVERSION",
  NONE: "NONE",
  OFFSITE_CONVERSIONS: "OFFSITE_CONVERSIONS",
  PAGE_LIKES: "PAGE_LIKES",
  POST_ENGAGEMENT: "POST_ENGAGEMENT",
  QUALITY_CALL: "QUALITY_CALL",
  QUALITY_LEAD: "QUALITY_LEAD",
  REACH: "REACH",
  REMINDERS_SET: "REMINDERS_SET",
  SUBSCRIBERS: "SUBSCRIBERS",
  THRUPLAY: "THRUPLAY",
  VALUE: "VALUE",
  VISIT_INSTAGRAM_PROFILE: "VISIT_INSTAGRAM_PROFILE",
} as const;

export const FACEBOOK_BUDGET_TYPE = {
  daily_budget: "daily_budget",
  lifetime_budget: "lifetime_budget",
};

export const FACEBOOK_PAYLOAD_MAP = {
  channel: "channel",
  name: "name",
  startDate: "start_time",
  endDate: "end_time",
  bidding_strategy: "bidding_strategy",
  bid: "bid_amount",
  optimizationGoal: "optimization_goal",
  creatives: "creatives",
  utmId: "utmId",
  status: "status",
  audienceType: "audienceType",
  audienceIds: "audienceIds",
};

export const FACEBOOK_STATUS = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  DELETED: "DELETED",
  PAUSED: "PAUSED",
};

export const FACEBOOK_BID_STRATEGY = {
  COST_CAP: "COST_CAP",
  LOWEST_COST_WITHOUT_CAP: "LOWEST_COST_WITHOUT_CAP",
  LOWEST_COST_WITH_BID_CAP: "LOWEST_COST_WITH_BID_CAP",
};

export const FACEBOOK_BILLING_EVENT = {
  app_installs: "APP_INSTALLS",
  clicks: "CLICKS",
  impressions: "IMPRESSIONS",
  link_clicks: "LINK_CLICKS",
  listing_interaction: "LISTING_INTERACTION",
  none: "NONE",
  offer_claims: "OFFER_CLAIMS",
  page_likes: "PAGE_LIKES",
  post_engagement: "POST_ENGAGEMENT",
  purchase: "PURCHASE",
  thruplay: "THRUPLAY",
};

export const FACEBOOK_AUDIENCE_TYPE = {
  FacebookAudiences: "FacebookAudiences",
  FacebookRetargetAudiences: "FacebookRetargetAudiences",
};

export const FACEBOOK_BID_ALLOWED_OPTIMIZATION_GOAL = [
  FACEBOOK_OPTIMIZATION_GOAL.IMPRESSIONS,
  FACEBOOK_OPTIMIZATION_GOAL.REACH,
];
