import { capitalize } from "lodash";
import { item } from "@/components/Button/CustomToggleButton";
import { ChannelSwitchState } from "../campaign.interface";
import { Channel } from "@/types";
import PrimaryDetails from "./PrimaryDetails";
import AdConfiguration from "./AdConfiguration";
import { CAMPAIGN_EXECUTION_TYPE } from "@/constants";

export const campaignTypeList = (objectiveTypes: any) =>
  objectiveTypes?.map((item: any) => ({
    label: convertCase(item.name),
    value: item.id,
  }));

function convertCase(value: string) {
  return value
    .split("_")
    .map((item) => capitalize(item))
    .join(" ");
}

export function checkIfChannelExists(switchState: ChannelSwitchState) {
  return Object.keys(switchState).some(
    (channel) => switchState[channel as Channel].state
  );
}

export const defaultFrequencyCap = {
  numberOfAds: null,
  duration: null,
  durationType: null,
};

export const STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

export const TopCampaignHeaders = [
  { id: "name", label: "Campaign Name" },
  { id: "advertiser", label: "Advertiser Name" },
  { id: "budget", label: "Budget (Impressions)" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "action", label: "Actions", noSort: true },
];

const fieldNames = {
  name: "name",
  type: "type",
  startDate: "startDate",
  endDate: "endDate",
} as const;

export const buttonList: item[] = [
  {
    label: "Active",
    value: STATUS.ACTIVE,
    color: "success",
  },
  { label: "InActive", value: STATUS.INACTIVE, color: "error" },
];
export const STEPS = [0, 1] as const;
export const STEPS_LABEL = ["", ""];
export const STEPFORM = {
  0: PrimaryDetails,
  1: AdConfiguration,
} as const;
export const STEP_BUTTON = {
  0: "Next",
  1: "Back",
} as const;

export const DATE_TYPE = {
  SINGLE: "SINGLE",
  DOUBLE: "DOUBLE",
};

export const radioOptions = [
  { label: "Run continuously from start date", value: DATE_TYPE.SINGLE },
  { label: "Set start and end date", value: DATE_TYPE.DOUBLE },
];
export const CampaignType = [
  { label: "Regular", value: CAMPAIGN_EXECUTION_TYPE.REGULAR },
  { label: "1 to 1", value: CAMPAIGN_EXECUTION_TYPE.ONETOONE },
];

export const lineItemType = [
  { label: "Banner", value: "0" },
  { label: "Video", value: "1" },
  { label: "Native", value: "2" },
];

export const durationTypeList = [
  { label: "Minute", value: 60 },
  { label: "Hour", value: 3600 },
  { label: "Day", value: 86400 },
  { label: "Week", value: 604800 },
];
