import * as React from "react";
import { getCampaignKeys, getCampaigns } from "@/apis/v1/campaign";
import { useQuery } from "@tanstack/react-query";
import { filterDataAccordingToAllowedAdvertisers } from "../../Advertiser/advertiser.validation";
import { CHANNELS, MODULES } from "@/constants";
import { validationSchema } from "./campaign.validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useUserContext } from "@/context/UserContext";
import dayjs from "dayjs";
import mapFilterQueryKeys from "@/utils/mapFilterQueryKeys";
import { omit } from "lodash";
import { useBeeswaxLineItem } from "../../Beeswax/beeswax.hook";
import { useLinkedinLineItem } from "../../Linkedin/linkedin.hook";
import { Channel } from "@/types";
import { useFacebookLineItem } from "../../LineItem/lineItem.hook";
import { ChannelSwitchState, FacebookAudienceType } from "./campaign.interface";
import { useMergedEmailAudiences } from "../../Audience/EmailAudience/emailAudience.hook";
import { useRetargetAudienceList } from "../../Audience/SiteVisitorAudience/siteVisitorAudience.hook";
import { FACEBOOK_AUDIENCE_TYPE } from ".././campaign.constant";

export function useCampaignKeysList(
  otherParams?: any,
  queryParams?: Record<string, any>
) {
  return useQuery(["campaigns", "keys"], getCampaignKeys, {
    ...otherParams,
    meta: {
      queryParams: {
        type: MODULES.CAMPAIGNS,
        ...queryParams,
      },
    },
  });
}

export const CAMPAIGNS_LIST_KEY = (keys?: Array<string | number | null>) => [
  "campaigns",
  ...mapFilterQueryKeys(keys),
];

interface useCampaignsListArgs {
  queryParams: {
    module: string;
    advertiserId?: number | null;
    page?: number;
    size?: number;
    isPagination?: boolean;
    type?: string
  };
}
export function useCampaignsList(meta?: useCampaignsListArgs) {
  return useQuery(
    CAMPAIGNS_LIST_KEY(
      Object.values(omit(meta?.queryParams, "isPagination") || [])
    ),
    getCampaigns,
    {
      meta: {
        queryParams: meta?.queryParams,
      },
      select: filterDataAccordingToAllowedAdvertisers("advertiserId"),
    }
  );
}

export function useCreateOrEditForm(data?: any) {
  const defaultValues = useDefaultValues(data);
  const switchState = useSwitchState({
    isDisplay: Boolean(data?.["BeeswaxCampaign"]),
    isLinkedin: Boolean(data?.["LinkedinCampaign"]),
    isFacebook: Boolean(data?.["FacebookCampaign"]),
  });

  const campaignValidationSchema = validationSchema({
    display: switchState.display.state,
    linkedin: switchState.linkedin.state,
    facebook: switchState.facebook.state,
  });

  const rForm = useForm<any>({
    defaultValues,
    resolver: yupResolver(campaignValidationSchema),
  });

  return { rForm, switchState };
}

function useSwitchState(value: {
  isDisplay: boolean;
  isLinkedin: boolean;
  isFacebook: boolean;
}): ChannelSwitchState {
  const [displaySwitch, setDisplaySwitch] = React.useState<boolean>(
    value.isDisplay ?? false
  );
  const [linkedinSwitch, setLinkedinSwitch] = React.useState<boolean>(
    value.isLinkedin ?? false
  );
  const [facebookSwitch, setFacebookSwitch] = React.useState<boolean>(
    value.isFacebook ?? false
  );
  return {
    [CHANNELS.DISPLAY]: { state: displaySwitch, setState: setDisplaySwitch },
    [CHANNELS.LINKEDIN]: { state: linkedinSwitch, setState: setLinkedinSwitch },
    [CHANNELS.FACEBOOK]: { state: facebookSwitch, setState: setFacebookSwitch },
  };
}

function useDefaultValues(data?: any) {
  const { loggedInAdvertiserId } = useUserContext();
  if (data) {
    let editDefaultValues: any = {
      name: data?.name ?? "",
      advertiserCompanyId: data?.advertiserId ?? "",
      startDate: data?.startDate,
    };
    if (data["BeeswaxCampaign"]) {
      editDefaultValues = {
        ...editDefaultValues,
        [CHANNELS.DISPLAY]: {
          continents: data?.BeeswaxCampaign?.continent ?? "",
          budgetType: data?.BeeswaxCampaign?.budgetType ?? "",
          budgetValue: data?.BeeswaxCampaign?.budget ?? null,
        },
      };
    }
    if (data["LinkedinCampaign"]) {
      editDefaultValues = {
        ...editDefaultValues,
        [CHANNELS.LINKEDIN]: {
          objectiveTypeId: data?.LinkedinCampaign?.objectiveTypeId,
        },
      };
    }
    if (data["FacebookCampaign"]) {
      editDefaultValues = {
        ...editDefaultValues,
        [CHANNELS.FACEBOOK]: {
          objective: data?.FacebookCampaign?.objectiveType,
        },
      };
    }

    return editDefaultValues;
  }

  return {
    name: "",
    advertiserCompanyId: loggedInAdvertiserId ?? "",
    startDate: dayjs(),
  };
}

export function useLineItem({
  args,
  channel,
}: {
  args: { enabled: boolean; onSuccess: (data: any) => void; id: any };
  channel: Channel;
}) {
  return hook[channel](args);
}

export function useAudienceForFacebookLineItem({
  advertiserId,
  audienceType,
}: {
  advertiserId: number;
  audienceType: FacebookAudienceType;
}) {
  const filterBySegmentId = (data: Record<string, any>) =>
    data.rows.filter((audience: Record<string, any>) =>
      Boolean(audience.segmentId)
    );
  const emailAudience = useMergedEmailAudiences(
    {
      queryParams: { channel: "facebook", advertiserId },
      options: {
        select: (data: Record<string, any>) => filterBySegmentId(data),
        enabled: Boolean(
          audienceType &&
            audienceType === FACEBOOK_AUDIENCE_TYPE.FacebookAudiences
        ),
      },
    },
    "withAdvertiserIdAndChannel"
  );
  const retargetAudience = useRetargetAudienceList({
    enabled: Boolean(
      audienceType &&
        audienceType === FACEBOOK_AUDIENCE_TYPE.FacebookRetargetAudiences
    ),
    advertiserId,
    options: {
      select: (data: Record<string, any>) => filterBySegmentId(data),
    },
  });

  const data = {
    [FACEBOOK_AUDIENCE_TYPE.FacebookAudiences]: emailAudience,
    [FACEBOOK_AUDIENCE_TYPE.FacebookRetargetAudiences]: retargetAudience,
  };

  if (audienceType) {
    return data[audienceType];
  }

  return { data: [], isLoading: false, isFetching: false };
}

const hook = {
  [CHANNELS.DISPLAY]: useBeeswaxLineItem,
  [CHANNELS.LINKEDIN]: useLinkedinLineItem,
  [CHANNELS.FACEBOOK]: useFacebookLineItem,
};

export function useCampaignChannelCardDisable(
  data: {
    display: {
      dspAccountId: string | null | undefined;
      campaign: Record<string, any> | undefined | null;
    };
    linkedin: {
      linkedinPageId: string | null | undefined;
      campaign: Record<string, any> | null | undefined;
    };
    facebook: {
      fbAdAccountId: string | null | undefined;
      campaign: Record<string, any> | undefined | null;
    };
  },
  isEdit: boolean
) {
  const disabledState = {
    isDisplayDisabled: true,
    isLinkedinDisabled: true,
    isFacebookDisabled: true,
  };

  if (data.display.dspAccountId) {
    if (isEdit && !data.display.campaign)
      disabledState.isDisplayDisabled = false;
    else disabledState.isDisplayDisabled = false;
  }
  if (data.linkedin.linkedinPageId) {
    if (isEdit && !data.linkedin.campaign)
      disabledState.isLinkedinDisabled = false;
    disabledState.isLinkedinDisabled = false;
  }
  if (data.facebook.fbAdAccountId) {
    if (isEdit && !data.facebook.campaign)
      disabledState.isFacebookDisabled = false;
    disabledState.isFacebookDisabled = false;
  }
  return disabledState;
}
