import React from "react";
import ReactDOM from "react-dom/client";

// mui imports
import { CssBaseline, ThemeProvider } from "@mui/material";

// local custom imports
import Routes from "@/App";
import { UserProvider } from "@/context/UserContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider as NoTiStackSnackbarProvider } from "notistack";
import CustomSnackbar from "./components/Snackbar/CustomSnackbar";
import { SnackbarProvider } from "./context/SnackbarContext";

import correctTypedErrorMessage from "@/utils/correctTypedErrorMessage";
import CustomSnackbarSecondary from "./components/Snackbar/CustomSnackbarSecondary";
import "./index.css";
import { messages } from "./messages";
import theme from "./theme/theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (_, error) => {
        if (
          [messages.displayNotConnected].includes(
            correctTypedErrorMessage(error)
          )
        ) {
          return false;
        }
        return true;
      },
    },
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <NoTiStackSnackbarProvider
            maxSnack={5}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            Components={{ custom: CustomSnackbarSecondary }}
            variant="custom"
          >
            <UserProvider>
              <CustomSnackbar />
              <CssBaseline />
              <Routes />
            </UserProvider>
          </NoTiStackSnackbarProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);
