import { QUOTA_TYPES, SMARTLEAD_ACCOUNT_TYPE } from "@/constants";
import { messages } from "@/messages";
import { omit } from "lodash";
import * as yup from "yup";

export const QUOTA_RESTRICTION_TYPE = {
  RESTRICTED: "restricted",
  UNRESTRICTED: "unrestricted",
};

const QUOTA_RESTRICTION_TYPES = Object.values(QUOTA_RESTRICTION_TYPE);

const createQuotaSchema = () =>
  yup.object({
    type: yup
      .string()
      .oneOf(QUOTA_RESTRICTION_TYPES)
      .required(messages.requiredField),
    limit: yup
      .number()
      .nullable()
      .when("type", {
        is: QUOTA_RESTRICTION_TYPE.RESTRICTED,
        then: (schema) => schema.min(1).required(messages.requiredField),
        otherwise: (schema) => schema.nullable(),
      }),
  });

export const AgencySchema = yup.object({
  name: yup.string().required(messages.requiredField),
  advertisersLimit: createQuotaSchema(),
  impressionsQuota: createQuotaSchema(),
  linkedinProfilesResolvedQuota: createQuotaSchema(),
  smartlead: yup.object({
    status: yup.boolean(),
    accountType: yup
      .string()
      .nullable()
      .when("status", {
        is: (status: any) => status === true,
        then: yup
          .string()
          .oneOf(Object.values(SMARTLEAD_ACCOUNT_TYPE))
          .required(messages.requiredField)
          .nullable(),
        otherwise: yup.string().notRequired().nullable(),
      }),
  }),
});

export type AgencyForm = yup.InferType<typeof AgencySchema>;

type QuotaPayload = {
  type: (typeof QUOTA_TYPES)[keyof typeof QUOTA_TYPES];
  limit: number;
};

type QuotaPayloadArray = QuotaPayload[];

export const mapQuotas = (data: AgencyForm) => {
  const mapQuotaLimit = (quotaInput: any) =>
    quotaInput?.type === QUOTA_RESTRICTION_TYPE.UNRESTRICTED
      ? -1
      : quotaInput?.limit;

  const quotas: QuotaPayloadArray = [];
  if (data.impressionsQuota) {
    quotas.push({
      type: QUOTA_TYPES.CAMPAIGN_IMPRESSIONS,
      limit: mapQuotaLimit(data.impressionsQuota),
    });
  }
  if (data.linkedinProfilesResolvedQuota) {
    quotas.push({
      type: QUOTA_TYPES.LINKEDIN_PROFILES_RESOLVED,
      limit: mapQuotaLimit(data.linkedinProfilesResolvedQuota),
    });
  }
  if (data.advertisersLimit) {
    quotas.push({
      type: QUOTA_TYPES.ADVERTISERS,
      limit: mapQuotaLimit(data.advertisersLimit),
    });
  }
  return {
    ...omit(
      data,
      "impressionsQuota",
      "linkedinProfilesResolvedQuota",
      "advertisersLimit"
    ),
    quotas,
  };
};

export const parseQuotas = (data: QuotaPayloadArray) => {
  const mapQuotaLimit = (quotaLimit: number) => ({
    type:
      quotaLimit === Number.POSITIVE_INFINITY
        ? QUOTA_RESTRICTION_TYPE.UNRESTRICTED
        : QUOTA_RESTRICTION_TYPE.RESTRICTED,
    limit: quotaLimit === Number.POSITIVE_INFINITY ? null : quotaLimit,
  });

  const defaultQuotaLimit: {
    type: (typeof QUOTA_RESTRICTION_TYPE)[keyof typeof QUOTA_RESTRICTION_TYPE];
    limit: number | null;
  } = {
    type: QUOTA_RESTRICTION_TYPE.UNRESTRICTED,
    limit: null,
  };

  if (!data)
    return {
      linkedinProfilesResolvedQuota: defaultQuotaLimit,
      impressionsQuota: defaultQuotaLimit,
      advertisersLimit: defaultQuotaLimit,
    };

  const quotas = {
    linkedinProfilesResolvedQuota: defaultQuotaLimit,
    impressionsQuota: defaultQuotaLimit,
    advertisersLimit: defaultQuotaLimit,
  };
  data.forEach((q) => {
    if (q.type === QUOTA_TYPES.LINKEDIN_PROFILES_RESOLVED) {
      quotas.linkedinProfilesResolvedQuota = mapQuotaLimit(q.limit);
    }
    if (q.type === QUOTA_TYPES.CAMPAIGN_IMPRESSIONS) {
      quotas.impressionsQuota = mapQuotaLimit(q.limit);
    }
    if (q.type === QUOTA_TYPES.ADVERTISERS) {
      quotas.advertisersLimit = mapQuotaLimit(q.limit);
    }
  });

  return quotas;
};

export const QUOTA_RESTRICTION_AUTOCOMPLETE_VALUES = [
  {
    label: "Restricted",
    value: QUOTA_RESTRICTION_TYPE.RESTRICTED,
  },
  {
    label: "Unrestricted",
    value: QUOTA_RESTRICTION_TYPE.UNRESTRICTED,
  },
];

export const smartLeadAccountList = [
  {
    label: "Platform Account",
    value: SMARTLEAD_ACCOUNT_TYPE.PLATFORM,
  },
  {
    label: "Agency Account",
    value: SMARTLEAD_ACCOUNT_TYPE.AGENCY,
  },
];

export function generatePayload(data: any) {
  const { smartlead, ...rest } = data;
  let payload = { ...rest };
  if (smartlead.status) payload = { ...payload, smartlead };
  return mapQuotas(payload);
}
