import routes from "@/routes";
import { lazy } from "react";
const IntentStream = lazy(() => import("@/modules/Report/IntentStream"));
const ListIntentStream = lazy(
  () => import("@/modules/Report/IntentStream/List")
);

const IntentStreamRoutes = {
  element: <IntentStream />,
  path: routes.REPORTS.INTENTSTREAM.INDEX,

  children: [
    {
      element: <ListIntentStream />,
      path: "",
    },
  ],
};

export default IntentStreamRoutes;
