import CustomDatePicker from "@/components/Form/CustomDatePicker";
import CustomLabel from "@/components/Form/CustomLabel";
import CustomAutocomplete from "@/components/Form/InputFields/CustomAutocomplete";
import CustomInput from "@/components/Form/InputFields/CustomInput";
import { FORM_COLUMN_SPACING, FORM_ROW_SPACING } from "@/constants";
import { useUserContext } from "@/context/UserContext";
import { useAdvertisersList } from "@/modules/Advertiser/advertiser.hook";
import { ICompany } from "@/modules/Company/companies.interface";
import { Channel } from "@/types";
import { Grid } from "@mui/material";
import * as React from "react";
import { useWatch } from "react-hook-form";
import { useCampaignChannelCardDisable } from "../campaign.hook";
import { IFormProps, SwitchState } from "../campaign.interface";
import DisplayForm from "./DisplayForm";
import FacebookForm from "./FacebookForm";
import LinkedinForm from "./LinkedinForm";

export default function InfoForm({
  control,
  errors,
  switchState,
  isEdit = false,
  campaign,
}: InfoFormProps) {
  const { loggedInAdvertiserId } = useUserContext();
  const watchAdvertiserValue = useWatch({
    name: "advertiserCompanyId",
    control,
  });
  const { data: advertisers, isLoading: advertisersLoading } =
    useAdvertisersList();
  const [selectedAdvertiser, setSelectedAdvertiser] =
    React.useState<ICompany>();
  React.useMemo(() => {
    setSelectedAdvertiser(
      advertisers?.find(
        (advertiser: ICompany) => advertiser.id === watchAdvertiserValue
      )
    );
  }, [watchAdvertiserValue, advertisers]);

  const { isDisplayDisabled, isLinkedinDisabled, isFacebookDisabled } =
    useCampaignChannelCardDisable(
      {
        display: {
          dspAccountId: selectedAdvertiser?.dspAccountId,
          campaign: campaign?.BeeswaxCampaign,
        },
        linkedin: {
          linkedinPageId: selectedAdvertiser?.linkedinPageId,
          campaign: campaign?.LinkedinCampaign,
        },
        facebook: {
          fbAdAccountId: selectedAdvertiser?.facebookAdAccount?.fbAdAccountId,
          campaign: campaign?.FacebookCampaign,
        },
      },
      isEdit
    );
  return (
    <Grid
      container
      justifyContent="center"
      columnSpacing={FORM_COLUMN_SPACING}
      rowSpacing={FORM_ROW_SPACING}
    >
      <Grid item xs={12}>
        <CustomAutocomplete
          name="advertiserCompanyId"
          label="Select Advertiser"
          control={control}
          itemList={advertisersList(advertisers)}
          error={errors?.advertiserCompanyId?.message}
          required={true}
          loading={advertisersLoading}
          disabled={Boolean(loggedInAdvertiserId) || isEdit}
          type="secondary"
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          name="name"
          label="Campaign Name"
          control={control}
          error={errors?.name?.message}
          required={true}
          type="secondary"
        />
      </Grid>
      <Grid item xs={12}>
        <CustomDatePicker
          control={control}
          name="startDate"
          label="Start Date"
          error={errors?.startDate?.message}
          disabled={isEdit}
          type="secondary"
        />
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <CustomLabel>Select Ad Channel</CustomLabel>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <DisplayForm
            disabled={isDisplayDisabled}
            displaySwitch={switchState.display.state}
            setDisplaySwitch={switchState.display.setState}
            control={control}
            errors={errors}
            isEdit={isEdit}
            BeeswaxCampaign={campaign?.BeeswaxCampaign}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <LinkedinForm
            disabled={isLinkedinDisabled}
            linkedinSwitch={switchState.linkedin.state}
            setLinkedinSwitch={switchState.linkedin.setState}
            control={control}
            errors={errors}
            isEdit={isEdit}
            LinkedinCampaign={campaign?.LinkedinCampaign}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <FacebookForm
            disabled={isFacebookDisabled}
            switchState={switchState.facebook.state}
            setSwitchState={switchState.facebook.setState}
            control={control}
            errors={errors}
            isEdit={isEdit}
            campaign={campaign?.FacebookCampaign}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
interface InfoFormProps extends IFormProps {
  switchState: Record<Channel, SwitchState>;
  campaign?: any;
}

function advertisersList(data: any) {
  return data?.map((item: any) => ({ label: item.name, value: item.id }));
}
