const ListColumns = [
  { id: "name", label: "Creative Name" },
  { id: "channel", label: "Channel" },
  { id: "lineItemAttached", label: "Line Items" },
  { id: "size", label: "Size", noSort: true },
  { id: "thumbnail", label: "Thumbnail", noSort: true },
  { id: "type", label: "Creative Type", noSort: true },
  { id: "action", label: "Actions", noSort: true },
];
const DetailListColumns = [
  { id: "creative", label: "Creative Name" },
  { id: "lineItemName", label: "Line Item Name" },
  { id: "utmName", label: "Utm Name" },
  { id: "active", label: "Active" },
];
const ASSET_TYPES = {
  BANNER: "banner",
  VIDEO: "video",
  NATIVE: "native",
};
export { ListColumns, DetailListColumns, ASSET_TYPES };
