import { ReactComponent as AdvertisersIcon } from "@/assets/dashboard/side-nav/Advertisers.svg";
import { ReactComponent as CampaignsIcon } from "@/assets/dashboard/side-nav/Campaigns.svg";
import { ReactComponent as DashboardIcon } from "@/assets/dashboard/side-nav/Dashboard.svg";
import { ReactComponent as LibraryIcon } from "@/assets/dashboard/side-nav/Library.svg";
import { ReactComponent as MatchedAudiencesIcon } from "@/assets/dashboard/side-nav/Matched Audiences.svg";
import { ReactComponent as SetupIcon } from "@/assets/dashboard/side-nav/Setup.svg";

import { ReactComponent as AssetsIcon } from "@/assets/dashboard/side-nav/Assets.svg";
import { ReactComponent as IntegrationsIcon } from "@/assets/dashboard/side-nav/Integrations.svg";
import { ReactComponent as ReportsIcon } from "@/assets/dashboard/side-nav/Reports.svg";
import { ReactComponent as SiteVisitorIcon } from "@/assets/dashboard/side-nav/SiteVisitor.svg";
import { ReactComponent as UsersIcon } from "@/assets/dashboard/side-nav/Users.svg";
import routes from "@/routes";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SourceIcon from "@mui/icons-material/Source";
import ListIcon from "@mui/icons-material/List";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AutoGraph from "@mui/icons-material/AutoGraph";
import { ISpecificCompanyIdTabs } from "./drawer.interface";
import { IPermissions } from "@/modules/User/user.interface";
import { MODULES } from "@/constants";

export const TABS = {
  DASHBOARD: { label: "Dashboard", path: "", icon: DashboardIcon },
  INTEGRATIONS: {
    label: "Integrations",
    path: routes.INTEGRATIONS,
    icon: IntegrationsIcon,
  },
  ADVERTISERS: {
    label: "Advertisers",
    path: routes.ADVERTISERS.INDEX,
    icon: AdvertisersIcon,
  },
  CAMPAIGNS: {
    label: "Campaigns",
    path: routes.CAMPAIGNS.INDEX,
    icon: CampaignsIcon,
    children: [
      {
        label: "General Campaign",
        path: routes.CAMPAIGNS.GENERAL_CAMPAIGN.INDEX,
        icon: IntegrationsIcon,
        key: "CAMPAIGNS",
      },
      {
        label: "Quick Campaign",
        path: routes.CAMPAIGNS.QUICK_CAMPAIGN.INDEX,
        icon: IntegrationsIcon,
        key: "QUICK_CAMPAIGNS",
      },
    ],
  },
  MATCHED_AUDIENCE: {
    label: "Matched Audiences",
    path: routes.AUDIENCES.INDEX,
    icon: MatchedAudiencesIcon,
  },
  SETUP: {
    label: "Setup",
    path: routes.REPORTS.REPORTS_SETUP.INDEX,
    icon: SetupIcon,
  },
  ASSETS: {
    label: "Assets",
    icon: AssetsIcon,
    path: undefined,
    children: [
      {
        label: "Creatives",
        path: routes.ASSETS.CREATIVES.INDEX,
        icon: IntegrationsIcon,
        key: "CREATIVES",
      },
      {
        label: "Templates",
        path: routes.ASSETS.TEMPLATE.INDEX,
        icon: IntegrationsIcon,
        key: "CREATIVE_TEMPLATES",
      },
      {
        label: "Forms",
        path: routes.ASSETS.LEADGEN.INDEX,
        icon: LibraryIcon,
        key: "FORMS",
      },
      { label: "UTMs", path: routes.UTM.INDEX, icon: LibraryIcon, key: "UTMS" },
    ],
  },
  TAG_MANAGER: {
    label: "Tag Manager",
    icon: SiteVisitorIcon,
    path: undefined,
    children: [
      { label: "Tag", path: "pixel", icon: IntegrationsIcon, key: "TAGS" },
      {
        label: "Conversions",
        path: routes.CONVERSION.INDEX,
        icon: LibraryIcon,
        key: "CONVERSIONS",
      },
    ],
  },
  VISITOR_INSIGHT: {
    label: "Visitor Insights",
    path: routes.VISITOR_INSIGHTS.INDEX,
    icon: QueryStatsIcon,
    children: [
      {
        label: "Company Insights",
        path: routes.VISITOR_INSIGHTS.COMPANY.INDEX,
        icon: IntegrationsIcon,
        key: "COMPANY_VISITOR_INSIGHTS",
      },
      {
        label: "Profile Insights",
        path: routes.VISITOR_INSIGHTS.PROFILE.INDEX,
        icon: IntegrationsIcon,
        key: "PROFILE_VISITOR_INSIGHTS",
      },
      {
        label: "Keyword Settings",
        path: routes.VISITOR_INSIGHTS.KEYWORD_SETTING.INDEX,
        icon: IntegrationsIcon,
        // FIXME: key should not be this
        key: "COMPANY_VISITOR_INSIGHTS",
      },
    ],
  },

  REPORTS: { label: "Reports", path: "reports", icon: ReportsIcon },
  CONTACT_BI: {
    label: "Contact BI",
    path: routes.CONTACT.INDEX,
    icon: AlternateEmailIcon,
  },
  USERS: { label: "Users", path: "users", icon: UsersIcon },
  PRESETS: { label: "Presets", path: routes.PRESET.INDEX, icon: SourceIcon },
  CUSTOM_LISTS: {
    label: "Custom Lists",
    path: routes.CUSTOM_LISTS.INDEX,
    icon: ListIcon,
  },
};

export function permittedTabs(permission: IPermissions, isAdmin: boolean) {
  if (isAdmin) {
    return SUPER_ADMIN_TABS;
  }
  const tabsValue = Object.values(TABS);

  const drawerList: typeof tabsValue = [];
  if (permission[MODULES.DASHBOARD]) {
    drawerList.push(checkSubTab(TABS.DASHBOARD, permission));
  }
  if (
    permission[MODULES.INTEGRATIONS_BEESWAX] ||
    permission[MODULES.INTEGRATIONS_FACEBOOK] ||
    permission[MODULES.INTEGRATIONS_LINKEDIN] ||
    permission[MODULES.INTEGRATIONS_HEYREACH] ||
    permission[MODULES.INTEGRATIONS_SMARTLEAD] ||
    permission[MODULES.INTEGRATIONS_DISPLAYVIDEO360] ||
    permission[MODULES.INTEGRATIONS_TRADEDESK] ||
    permission[MODULES.INTEGRATIONS_SALESFORCE] ||
    permission[MODULES.INTEGRATIONS_HUBSPOT]
  ) {
    drawerList.push(checkSubTab(TABS.INTEGRATIONS, permission));
  }
  if (permission[MODULES.ADVERTISERS]) {
    drawerList.push(checkSubTab(TABS.ADVERTISERS, permission));
  }
  if (permission[MODULES.CAMPAIGNS] || permission[MODULES.QUICK_CAMPAIGNS]) {
    drawerList.push(checkSubTab(TABS.CAMPAIGNS, permission));
  }
  if (
    permission[MODULES.AUDIENCE_ACCOUNT] ||
    permission[MODULES.AUDIENCE_EMAIL] ||
    permission[MODULES.AUDIENCE_SITE_VISITOR] ||
    permission[MODULES.AUDIENCE_MANAGER]
  ) {
    drawerList.push(checkSubTab(TABS.MATCHED_AUDIENCE, permission));
  }
  if (
    permission[MODULES.CREATIVES] ||
    permission[MODULES.CREATIVE_TEMPLATES] ||
    permission[MODULES.FORMS] ||
    permission[MODULES.UTMS]
  ) {
    drawerList.push(checkSubTab(TABS.ASSETS, permission));
  }

  if (permission[MODULES.TAGS] || permission[MODULES.CONVERSIONS]) {
    drawerList.push(checkSubTab(TABS.TAG_MANAGER, permission));
  }

  if (
    permission[MODULES.COMPANY_VISITOR_INSIGHTS] ||
    permission[MODULES.PROFILE_VISITOR_INSIGHTS]
  ) {
    drawerList.push(checkSubTab(TABS.VISITOR_INSIGHT, permission));
  }
  if (permission[MODULES.CONTACT_BI]) {
    drawerList.push(checkSubTab(TABS.CONTACT_BI, permission));
  }
  if (
    permission[MODULES.CAMPAIGN_STATS] ||
    permission[MODULES.ABM_STATS] ||
    permission[MODULES.REPORTS_SETUP]
  ) {
    drawerList.push(checkSubTab(TABS.REPORTS, permission));
  }
  if (
    permission[MODULES.AGENCY_USERS] ||
    permission[MODULES.ADVERTISER_USERS]
  ) {
    drawerList.push(checkSubTab(TABS.USERS, permission));
  }
  if (permission[MODULES.TARGETING_EXPRESSION_PRESET]) {
    drawerList.push(checkSubTab(TABS.PRESETS, permission));
    drawerList.push(checkSubTab(TABS.CUSTOM_LISTS, permission));
  }

  return drawerList;
}

export function getFirstTabPath(tabs: ReturnType<typeof permittedTabs>) {
  return tabs[0].path ?? tabs[0]?.children?.[0].path;
}

function checkSubTab(tab: any, permission: any) {
  if (tab.children) {
    const tabs: any = [];
    tab.children.forEach((child: any) => {
      if (permission[child.key]) tabs.push(child);
    });
    tab.children = tabs;
  }
  return tab;
}

export const SUPER_ADMIN_TABS = [
  TABS.ADVERTISERS,
  TABS.MATCHED_AUDIENCE,
  TABS.SETUP,
  TABS.ASSETS,
  TABS.TAG_MANAGER,
];
export const AGENCY_ADMIN_TABS = [
  TABS.DASHBOARD,
  TABS.INTEGRATIONS,
  TABS.ADVERTISERS,
  TABS.CAMPAIGNS,
  TABS.MATCHED_AUDIENCE,
  TABS.ASSETS,
  TABS.TAG_MANAGER,
  TABS.VISITOR_INSIGHT,
  TABS.REPORTS,
  TABS.CONTACT_BI,
  TABS.USERS,
];
export const AGENCY_USER_TABS = [
  TABS.DASHBOARD,
  TABS.ADVERTISERS,
  TABS.CAMPAIGNS,
  TABS.MATCHED_AUDIENCE,
  TABS.ASSETS,
  TABS.TAG_MANAGER,
  TABS.VISITOR_INSIGHT,
  TABS.REPORTS,
  TABS.CONTACT_BI,
  TABS.USERS,
];
export const ADVERTISER_TABS = [
  TABS.DASHBOARD,
  TABS.INTEGRATIONS,
  TABS.CAMPAIGNS,
  TABS.MATCHED_AUDIENCE,
  TABS.ASSETS,
  TABS.TAG_MANAGER,
  TABS.VISITOR_INSIGHT,
  TABS.REPORTS,
  TABS.CONTACT_BI,
  TABS.USERS,
];
export const SPECIFIC_COMPANY_ID_TABS: ISpecificCompanyIdTabs = {
  206: [TABS.INTEGRATIONS, TABS.ADVERTISERS, TABS.MATCHED_AUDIENCE, TABS.USERS],
  211: [TABS.INTEGRATIONS, TABS.ADVERTISERS, TABS.MATCHED_AUDIENCE, TABS.USERS],
};
